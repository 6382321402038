import React, {useState} from 'react';
import { Row, Col, Form, Select, Input } from 'antd';

const { Option } = Select;


function EquipmentCalculatComponent({name, index, request_type, calculatedExpRate, form}) {

  const currencies = [
    'USD','EUR','GBP','JPY','AUD','CAD','CHF','CNY','SEK','NZD','NOK','SGD','KRW','TRY','MXN','INR','BRL','ZAR','HKD','RUB',
    'DKK','PLN','THB','IDR','HUF','CZK','ILS','CLP','PHP','AED','COP','MYR','RON','SAR','HRK','BGN','EGP','QAR','VND','ARS','NGN',
    'PKR','UAH','KWD','BDT','IQD','MAD','OMR','LKR','TWD',
  ];
  
  return (
    <Row gutter={[12, 12]}>
                   <Col lg={8} md={8} sm={24} xs={24}>
                     <Form.Item
                       name={[name, `currency`]}
                       label="Currency"
                       rules={[
                         {
                           required: true,
                           message: 'Please select the currency',
                         },
                       ]}
                     >
                       <Select placeholder="Select Currency" size="large">
                         {currencies.map((currency, index) => (
                           <Option key={currency} value={currency}>
                             {currency}
                           </Option>
                         ))}
                       </Select>
                     </Form.Item>
                   </Col>
 
                   <Col lg={8} md={8} sm={24} xs={24}>
                     <Form.Item
                       name={[name, 'amount']}
                       onChange={(e) => {
                         const assetDetailFields = form.getFieldValue([request_type,index,'asset_details']);
                         assetDetailFields.forEach((fieldd, indexx) => {
                            calculatedExpRate(fieldd.qty_inventory, index, indexx, request_type);  
                          });
                        }}
                       label="Price"
                       rules={[
                         {
                           required: true,
                           message: 'Please enter the amount',
                         },
                       ]}
                     >
                       <Input placeholder="Type Amount" size="large" />
                     </Form.Item>
                   </Col>
 
                   <Col lg={8} md={8} sm={24} xs={24}>
                     <Form.Item
                       name={[name, 'rate']}
                        onChange={(e) => {
                         const assetDetailFields = form.getFieldValue([request_type,index,'asset_details']);
                         assetDetailFields.forEach((fieldd, indexx) => {
                            calculatedExpRate(fieldd.qty_inventory, index, indexx, request_type);  
                          });
                        }}
                       label="Exchange Rate"
                       rules={[
                         {
                           required: true,
                           message: 'Please enter the exchange rate',
                         },
                       ]}
                     >
                       <Input placeholder="Type Exchange Rate" size="large" />
                     </Form.Item>
                   </Col>
 
                  
                 </Row>
  )
}

export default EquipmentCalculatComponent